import {
  Button,
  Card,
  Chip,
  Divider,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Slider,
} from '@nextui-org/react';
import React, { useEffect, useMemo, useState } from 'react';

import { ResellerInfo } from '../types';

interface CreateOrderModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (
    qty: number,
    discount_percentage: number,
    discount_name: string
  ) => void;
  isSingleTrialUnit?: boolean;
  resellerInfo: ResellerInfo | undefined;
}

const CreateOrderModal: React.FC<CreateOrderModalProps> = ({
  isOpen,
  onClose,
  onSubmit,
  isSingleTrialUnit = false,
  resellerInfo,
}) => {
  const [quantity, setQuantity] = useState<number>(isSingleTrialUnit ? 1 : 5);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const regularPrice = 249;

  useEffect(() => {
    setQuantity(isSingleTrialUnit ? 1 : 5);
  }, [isSingleTrialUnit]);

  const discountPercentage = useMemo(() => {
    if (isSingleTrialUnit) return 50.0;
    if (quantity === 5) return 32.0;
    if (quantity === 10) return 35.0;
    if (quantity === 15) return 40.0;
    if (quantity === 20) return 43.0;
    if (quantity === 25) return 45.0;
    return 0;
  }, [quantity, isSingleTrialUnit]);

  const discountedPrice = useMemo(() => {
    return regularPrice * (1 - discountPercentage / 100);
  }, [discountPercentage]);

  const totalCost = useMemo(() => {
    return discountedPrice * quantity;
  }, [discountedPrice, quantity]);

  const totalSavings = useMemo(() => {
    return regularPrice * quantity - totalCost;
  }, [quantity, totalCost]);

  const discountName = useMemo(() => {
    return `${resellerInfo?.company_name || 'Authorized Reseller'} - ${discountPercentage}%`;
  }, [resellerInfo?.company_name, discountPercentage]);

  const handleSubmit = () => {
    onSubmit(quantity, discountPercentage, discountName);
    setIsLoading(true);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader className="flex flex-col gap-1">New Order</ModalHeader>
        <ModalBody className="gap-6">
          <Input
            type="text"
            label="Product"
            defaultValue="AIRLOCK™ One"
            disabled
          />
          {!isSingleTrialUnit && (
            <Slider
              label="How many units would you like to order?"
              color="secondary"
              size="md"
              hideValue
              showSteps
              step={5}
              minValue={5}
              maxValue={25}
              marks={[
                {
                  value: 5,
                  label: '5',
                },
                {
                  value: 10,
                  label: '10',
                },
                {
                  value: 15,
                  label: '15',
                },
                {
                  value: 20,
                  label: '20',
                },
                {
                  value: 25,
                  label: '25',
                },
              ]}
              defaultValue={5}
              value={quantity}
              onChange={value => setQuantity(value as number)}
              className="max-w-md"
            />
          )}
          <Card className="p-5">
            <p>
              Exclusive Discount: <strong>{discountPercentage}%</strong>
            </p>
            <Divider className="my-3" />
            <p>
              Cost per unit: ${discountedPrice.toFixed(2)}{' '}
              <span className="text-sm text-red-600">
                <span className="line-through">${regularPrice.toFixed(2)}</span>
                <Chip className="ml-2 bg-red-200 text-xs !font-bold">
                  SAVE ${(regularPrice - discountedPrice).toFixed(2)}
                </Chip>
              </span>
            </p>
            <Divider className="my-3 bg-gray-800" />
            <p className="font-bold">Total: ${totalCost.toFixed(2)}</p>
            <p className="text-red-600 text-md font-bold">
              Total Savings: ${totalSavings.toFixed(2)}
            </p>
          </Card>
        </ModalBody>
        <ModalFooter>
          <Button color="default" variant="light" onPress={onClose}>
            Cancel
          </Button>
          <Button
            color="primary"
            onPress={handleSubmit}
            isLoading={isLoading}
            className="bg-gradient-to-r from-gray-800 to-airlock-purple "
          >
            Checkout
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateOrderModal;
