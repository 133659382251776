import { Button, Input } from '@nextui-org/react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/modal';

import React from 'react';
import { ResellerInfo } from '../../types';

interface EditResellerModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: () => void;
  formData: Partial<ResellerInfo>;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const EditResellerModal: React.FC<EditResellerModalProps> = ({
  isOpen,
  onClose,
  onSave,
  formData,
  handleChange,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Edit Reseller</ModalHeader>
        <ModalBody>
          <Input
            name="company_name"
            placeholder="Company Name"
            variant="bordered"
            value={formData.company_name || ''}
            onChange={handleChange}
          />
          <Input
            name="first_name"
            placeholder="First Name"
            variant="bordered"
            value={formData.first_name || ''}
            onChange={handleChange}
          />
          <Input
            name="last_name"
            placeholder="Last Name"
            variant="bordered"
            value={formData.last_name || ''}
            onChange={handleChange}
          />
          <Input
            name="email"
            placeholder="Email"
            variant="bordered"
            value={formData.email || ''}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onSave} variant="flat">
            Save
          </Button>
          <Button color="danger" variant="flat" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EditResellerModal;
