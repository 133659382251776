import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Table,
  TableBody,
  TableCell,
  TableColumn,
  TableHeader,
  TableRow,
} from '@nextui-org/react';

import React from 'react';
import { ResellerInfo } from '../../types';
import { VerticalDotsIcon } from '../../assets/VerticalDotsIcon';
import moment from 'moment';

interface ResellerTableProps {
  resellers: ResellerInfo[];
  onEdit: (reseller: ResellerInfo) => void;
  onDelete: (id: string) => void;
}

const ResellerTable: React.FC<ResellerTableProps> = ({
  resellers,
  onEdit,
  onDelete,
}) => {
  return (
    <Table isCompact aria-label="Reseller Management Table">
      <TableHeader>
        <TableColumn min-width={200}>Company</TableColumn>
        <TableColumn>First Name</TableColumn>
        <TableColumn>Last Name</TableColumn>
        <TableColumn minWidth={80}>Email</TableColumn>
        <TableColumn>Shopify ID</TableColumn>
        <TableColumn>Auth0 ID</TableColumn>
        <TableColumn minWidth={20}>Joined</TableColumn>
        <TableColumn>Actions</TableColumn>
      </TableHeader>
      <TableBody>
        {resellers.map(reseller => (
          <TableRow key={reseller.id}>
            <TableCell>{reseller.company_name}</TableCell>
            <TableCell>{reseller.first_name}</TableCell>
            <TableCell>{reseller.last_name}</TableCell>
            <TableCell>{reseller.email}</TableCell>
            <TableCell>{reseller.shopify_customer_id}</TableCell>
            <TableCell>{reseller.auth0_id}</TableCell>
            <TableCell>
              {moment(reseller.createdAt).format('MMMM DD, YYYY')}
            </TableCell>
            <TableCell>
              <div className="relative flex justify-end items-center gap-2">
                <Dropdown className="bg-background border-1 border-default-200">
                  <DropdownTrigger>
                    <Button isIconOnly radius="full" size="sm" variant="light">
                      <VerticalDotsIcon
                        width={20}
                        height={20}
                        className="text-default-400"
                      />
                    </Button>
                  </DropdownTrigger>
                  <DropdownMenu>
                    <DropdownItem onClick={() => onEdit(reseller)}>
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => onDelete(reseller.id)}
                      className="text-red-500"
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ResellerTable;
