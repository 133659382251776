import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import React, { useEffect, useState } from 'react';

import Admin from './Admin/Admin';
import Contact from './Contact';
import Orders from './Orders';
import Resources from './Resources';
import Sidebar from './Sidebar';
import { Spinner } from '@nextui-org/react';
import TopBar from './TopBar';
import { useAuth0 } from '@auth0/auth0-react';

const Dashboard: React.FC = () => {
  const { isAuthenticated, isLoading, loginWithRedirect, user } = useAuth0();
  const [isCheckingSession, setIsCheckingSession] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    } else if (!isLoading && isAuthenticated && user) {
      const roles = user['https://api.airlock/roles'] || [];
      setIsAdmin(roles.includes('admin'));
      setIsCheckingSession(false);
    }
  }, [isLoading, isAuthenticated, loginWithRedirect, user]);

  if (isLoading || isCheckingSession) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <Spinner size="lg" color="secondary" />
        <p className="mt-4 text-xl font-semibold text-airlock-purple">
          Loading...
        </p>
      </div>
    );
  }

  if (!isAuthenticated) {
    return null;
  }

  return (
    <Router>
      <div className="flex flex-col h-screen w-screen max-w-screen">
        <TopBar />
        <div className="flex ">
          <div className="min-h-screen h-full w-1/6 bg-gray-200 p-4 hidden md:block z-10 ">
            <Sidebar />
          </div>
          <div className="flex-1 p-8 overflow-hidden  max-w-screen">
            <Routes>
              <Route path="/orders" element={<Orders />} />
              <Route path="/resources" element={<Resources />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/" element={<Navigate to="/orders" replace />} />
              <Route
                path="/admin"
                element={
                  isAdmin ? <Admin /> : <Navigate to="/orders" replace />
                }
              />
            </Routes>
          </div>
        </div>
      </div>
    </Router>
  );
};

export default Dashboard;
