import { Link, useLocation } from 'react-router-dom';

import { Button } from '@nextui-org/react';
// Sidebar.tsx
import React from 'react';

interface SidebarButtonProps {
  to: string;
  children: React.ReactNode;
  isActive: boolean;
}

const SidebarButton = ({ to, children, isActive }: SidebarButtonProps) => (
  <Button
    as={Link}
    to={to}
    className={`w-full justify-start text-medium font-medium ${isActive ? 'bg-white' : ''}`}
    variant="light"
  >
    {children}
  </Button>
);

const Sidebar: React.FC = () => {
  const location = useLocation();

  return (
    <ul className="space-y-2">
      <li>
        <SidebarButton to="/orders" isActive={location.pathname === '/orders'}>
          Orders
        </SidebarButton>
      </li>
      <li>
        <SidebarButton
          to="/resources"
          isActive={location.pathname === '/resources'}
        >
          Resources
        </SidebarButton>
      </li>
      <li>
        <SidebarButton
          to="/contact"
          isActive={location.pathname === '/contact'}
        >
          Contact
        </SidebarButton>
      </li>
    </ul>
  );
};

export default Sidebar;
