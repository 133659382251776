// services/resellerService.ts
import { ResellerInfo } from '../types';

// Fetch reseller info from Database
export const fetchResellerInfo = async (
  token: string
): Promise<ResellerInfo> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/reseller/info`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};
// Fetch all resellers from the database
export const fetchAllResellers = async (
  token: string
): Promise<ResellerInfo[]> => {
  console.log('Fetching all resellers...');
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/reseller/all`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!response.ok) {
      console.error(
        'Failed to fetch resellers:',
        response.status,
        response.statusText
      );
      throw new Error('Failed to fetch resellers');
    }

    const data = await response.json();
    console.log('Successfully fetched resellers:', data.length);
    return data;
  } catch (error) {
    console.error('Error fetching resellers:', error);
    throw error;
  }
};

// Client service: Update a reseller's information
export const updateResellerInfo = async (
  token: string,
  resellerData: Partial<ResellerInfo>
): Promise<ResellerInfo> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/reseller/info`,
    {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(resellerData), // Ensure this includes the 'id'
    }
  );

  if (!response.ok) {
    throw new Error('Failed to update reseller information');
  }

  return response.json();
};

// Delete a reseller by ID
export const deleteReseller = async (
  token: string,
  id: string
): Promise<void> => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/reseller/info`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw new Error('Failed to delete reseller');
  }
};
