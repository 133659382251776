// AddResellerModal.tsx

import { Button, Input } from '@nextui-org/react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@nextui-org/modal';
import React, { useState } from 'react';

import { ResellerInfo } from '../../types';
import { useAuth0 } from '@auth0/auth0-react';

interface AddResellerModalProps {
  isOpen: boolean;
  onClose: () => void;
  createAuth0User: (formData: Partial<ResellerInfo>) => Promise<string>;
  refreshResellers: () => void;
}

const AddResellerModal: React.FC<AddResellerModalProps> = ({
  isOpen,
  onClose,
  createAuth0User,
  refreshResellers,
}) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  const [formData, setFormData] = useState<Partial<ResellerInfo>>({
    tier: 'Silver',
  });
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveClick = async () => {
    if (!formData.email || isLoading) {
      setError('Email is required to create an Auth0 user.');
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      const token = await getAccessTokenSilently();
      const auth0Id = await createAuth0User(formData);

      const resellerData = {
        ...formData,
        auth0_id: auth0Id,
        shopify_customer_id: formData.shopify_customer_id || null,
      };

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reseller/create`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(resellerData),
        }
      );

      if (response.ok) {
        await refreshResellers();
      } else {
        throw new Error('Failed to save reseller in the database');
      }

      onClose();
    } catch (err) {
      console.error('Error creating reseller:', err);
      setError('Failed to create reseller.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalContent>
        <ModalHeader>Add New Reseller</ModalHeader>
        <ModalBody>
          {error && <p className="text-red-500">{error}</p>}
          <Input
            name="first_name"
            placeholder="First Name"
            variant="bordered"
            value={formData.first_name || ''}
            onChange={handleChange}
          />
          <Input
            name="last_name"
            placeholder="Last Name"
            variant="bordered"
            value={formData.last_name || ''}
            onChange={handleChange}
          />
          <Input
            name="company_name"
            placeholder="Company Name"
            variant="bordered"
            value={formData.company_name || ''}
            onChange={handleChange}
          />
          <Input
            name="email"
            placeholder="Email"
            variant="bordered"
            value={formData.email || ''}
            onChange={handleChange}
          />
          <Input
            name="shopify_customer_id"
            placeholder="Shopify Customer ID"
            variant="bordered"
            value={formData.shopify_customer_id || ''}
            onChange={handleChange}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSaveClick}
            variant="flat"
            disabled={isLoading}
          >
            {isLoading ? 'Saving...' : 'Save'}
          </Button>
          <Button color="danger" variant="flat" onClick={onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddResellerModal;
